<template>
  <div class="head_top">
    <div class="font_center">云服务</div>
    <img src="../../../../public/images/官网底部文字对应的二级页面/云存储服务/云存储1.jpg" width="100%" height="100%"  lazy>
    <img src="../../../../public/images/官网底部文字对应的二级页面/云存储服务/云存储2.jpg"width="100%" height="100%" lazy>
    <img src="../../../../public/images/官网底部文字对应的二级页面/云存储服务/云存储3.jpg" width="100%" height="100%" lazy>
    <img src="../../../../public/images/官网底部文字对应的二级页面/云存储服务/云存储4.jpg" width="100%" height="100%" lazy>
    <img src="../../../../public/images/官网底部文字对应的二级页面/云存储服务/云存储5.jpg" width="100%" height="100%" lazy>
    <img src="../../../../public/images/官网底部文字对应的二级页面/云存储服务/云存储服务.jpg" width="100%" height="100%" lazy>
    <div>
      <el-collapse v-model="activeName" accordion class="cjwt">
        <el-collapse-item title="常见问题">
        </el-collapse-item>
        <el-collapse-item title="1.如何试用云存储？" name="1">
          <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
          <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
        </el-collapse-item>
        <el-collapse-item title="2. 如何购买云存储？" name="2">
          <div>控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；</div>
          <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
        </el-collapse-item>
        <el-collapse-item title="3. 云存储怎么续费？ " name="3">
          <div>简化流程：设计简洁直观的操作流程；</div>
          <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
          <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div>
        </el-collapse-item>
        <el-collapse-item title="4. 云存储有效期可以暂停计时吗?" name="4">
          <div>用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；</div>
          <div>结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。</div>
        </el-collapse-item>
        <el-collapse-item title="5. 云存储功能有效期是多久？" name="5">
          <div>用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；</div>
          <div>结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。</div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "云存储服务",
  data() {
    return {
      activeName: '1'
    };
  }
}
</script>

<style scoped>
.font_center{
  font-size: 24px;
  text-align: center;
}
.cjwt{
  width: 80%;
  margin: auto;
  font-size: 16px;
}
</style>